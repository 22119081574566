@import "~@codecademy/gamut-styles/utils";

.MathBlock {
  @include font-smoothing;
  display: block;
  padding: 1rem 0;
  text-align: left;
  font-weight: normal;
  color: inherit;
  overflow: auto;
  div span {
    text-align: center;
  }
}

.wrap,
.wrap pre {
  word-wrap: break-all;
  white-space: pre-wrap;
}
